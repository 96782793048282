<template>
  <div class="search">
    <div class="search-input" @click="searchGoods">
      <img src="@/assets/goods/icon-search@2x.png" class="search-icon" />
      <!-- <input type="text" class="input" v-model="goodsName" /> -->
      <div class="input">请输入关键词</div>
      <div class="search-btn" :style="{ background: backColor }">搜索</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    backColor: {
      default: "red",
    },
  },
  data() {
    return {
      goodsName: "",
      showSearch: false,
    };
  },
  methods: {
    searchGoods() {
      this.$emit("searchGoods", this.goodsName);
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  padding: 6px 13px 13px 13px;
  .search-input {
    position: relative;
    display: flex;
    align-items: center;
    height: 39px;
    background: rgb(255, 255, 255);
    border-radius: 21px;
    padding-left: 16px;
    padding-right: 3px;
    .search-icon {
      width: 17px;
      height: 17px;
    }
    .input {
      margin-left: 10px;
      font-size: 16px;
      border: none;
      font-weight: 400;
      color: #999;
    }
    .search-btn {
      position: absolute;
      top: 50%;
      right: 3px;
      transform: translateY(-50%);
      width: 74px;
      height: 34px;
      text-align: center;
      line-height: 34px;
      border-radius: 34px;
      font-size: 18px;
      color: #fff;
    }
  }
}
</style>
