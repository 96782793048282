<template>
  <div class="list">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        class="goods-card"
        v-for="(item, index) in list"
        :key="index"
        @click="goodsInfo(index)"
      >
        <img :src="item.goods_img" class="goods-img" />
        <div class="goods-info">
          <div class="goods-name">
            {{ item.goods_title }}
          </div>
          <div class="bottom-info">
            <div class="price">
              <div class="now-price">
                <span class="price-common">￥</span>
                <span class="integer">{{ toInteger(item.zk_price) }}</span>
                <span class="price-common">{{
                  twoFloating(item.zk_price)
                }}</span>
              </div>
              <div class="original-price">￥{{ item.price }}</div>
            </div>
            <div class="goods-tag" :class="{ 'tag-color': tagType == 'Tmall' }">
              {{ tagText }}
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getJdGoodsList, getTbGoodsList } from "@/api/platform";
import tools from "@/common/js/tools";

export default {
  props: {
    tagText: {
      default: "优选商品",
    },
    tagType: {
      default: "Tmall",
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      list: [],
      isAjax: false,
      goodsName: "",
    };
  },
  mounted() {
    // this.startGoodsList();
  },
  methods: {
    goodsInfo(goodsIndex) {
      let goodsInfo = this.list[goodsIndex];
      window.location.href = goodsInfo.goods_link;
    },
    onLoad() {
      this.getGoodsList();
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
    },
    selectGoodsList(goodsName) {
      this.goodsName = goodsName;
      this.startGoodsList();
    },
    startGoodsList() {
      this.page = 1;
      this.list = [];
      this.isAjax = false;
      this.getGoodsList();
    },
    getGoodsList() {
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      if (this.tagType === "Tmall") {
        this.getTbGoodsList();
      } else {
        this.getJdGoodsList();
      }
    },
    async getTbGoodsList() {
      let ret = await getTbGoodsList({
        page: this.page,
        goods_name: this.goodsName,
      });
      this.setGoodList(ret);
    },
    async getJdGoodsList() {
      let ret = await getJdGoodsList({
        page: this.page,
        goods_name: this.goodsName,
      });
      this.setGoodList(ret);
    },
    setGoodList(goodsList) {
      this.isAjax = false;
      if (goodsList.code * 1 === 1) {
        console.log(goodsList.data);
        if (goodsList.data.length <= 0) {
          return false;
        }
        ++this.page;
        this.list = this.list.concat(goodsList.data);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  height: 100%;
  padding: 13px 13px 0 13px;
  background: #f5f5f5;
  border-radius: 13px 13px 0px 0px;
}
.goods-card {
  display: flex;
  background: rgba(255, 255, 255, 0.39);
  border-radius: 13px;
  padding: 16px 15px 12px 12px;
  margin-bottom: 13px;
  .goods-img {
    flex-shrink: 0;
    width: 129px;
    height: 129px;
    border-radius: 6px;
    background-color: rgb(221, 217, 217);
  }
}
.goods-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-left: 10px;
  .goods-name {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
  }
  .bottom-info {
    .goods-tag {
      display: table;
      margin-top: 3px;
      font-size: 13px;
      background: linear-gradient(90deg, #f4180f 0%, #ff6731 100%);
      border-radius: 3px;
      color: #fff;
      padding: 1px 4px;
    }
    .tag-color {
      display: table;
      background: #fff;
      color: #985dff;
      border: 1px solid #985dff;
    }
    .price {
      display: flex;
      align-items: center;
    }
    .original-price {
      text-decoration: line-through;
      color: #b9babe;
      font-size: 13px;
      margin-left: 6px;
    }
  }
  .now-price {
    color: #ed301d;
    .price-common {
      font-size: 12px;
    }
    .integer {
      font-weight: 500;
      font-size: 19px;
    }
  }
}
</style>
